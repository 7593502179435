<!--  -->

<olt-control-label [srOnly]="false" [validationState]="validationState">
  <ng-content>
  </ng-content>
</olt-control-label>

<div class="olt-form-group" *ngIf="controlName != null">
  <div class="btn-group btn-group-toggle">
    <label class="btn btn-secondary" [class.active]="formGroup.get(controlName)?.value === true">
      <input type="radio" [name]="controlName" [formControl]="formGroup.get(controlName)" [value]="true"> Yes
    </label>
    <label class="btn btn-secondary" [class.active]="formGroup.get(controlName)?.value === false">
      <input type="radio" [name]="controlName" [formControl]="formGroup.get(controlName)" [value]="false"> No
    </label>
  </div>
</div>

<ng-container *ngIf="showError">
  <olt-help-block [message]="firstError"></olt-help-block>
</ng-container>


<!-- <div *ngIf="control != null">
  <div class="btn-group btn-group-toggle">
    <label class="btn btn-secondary" [class.active]="control?.value === true">
      <input type="radio" [formControl]="control" [value]="true"> Yes
    </label>
    <label class="btn btn-secondary" [class.active]="control?.value === false">
      <input type="radio" [formControl]="control" [value]="false"> No
    </label>
  </div>
</div> -->