import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

export class Address {
  line1: string;
  line2?: string;
  city: string;
  state: string;
  zip: string;

  public static formGroup(fb: UntypedFormBuilder, validators?: Validators[], defaultState?: string): UntypedFormGroup {
    return fb.group({
      // validateAddress: [true],
      line1: [null, validators],
      line2: [null],
      city: [null, validators],
      state: [defaultState, validators],
      zip: [null, validators],
    }, { updateOn: 'blur' });
  }

  constructor(data?: any) {
    if (data == null) {
      return;
    }
    this.line1 = data.line1;
    this.line2 = data.line2;
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
  }
}
