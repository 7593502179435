import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PagedParam, Paged, ApiParams } from '@outerlimitstech/ngx-app-core';
import { Observable } from 'rxjs';
import { User } from 'bgcslib';




@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiService: ApiService) { }

  getAll(pagedParams: PagedParam, roleId?: number, searchText?: string): Observable<Paged<User>> {
    const params = new ApiParams();
    params.appendParams(pagedParams.getAll());
    if (roleId != null && roleId > 0) {
      params.append('roleId', roleId);
    }
    if (searchText != null) {
      params.append('searchText', searchText);
    }
    return this.apiService.doGet(`users`, params);
  }

  get(userId: number): Observable<User> {
    return this.apiService.doGet(`users/${userId}`);
  }

  update(userId: number, data: any): Observable<User> {
    return this.apiService.doPut<User>(`users/${userId}`, data);
  }

  add(data: any): Observable<User> {
    return this.apiService.doPost<User>(`users`, data);
  }

  setPassword(userId: number, newPassword: string, confirmPassword: string): Observable<boolean> {
    const data = {
      newPassword,
      confirmPassword,
    };
    return this.apiService.doPut<boolean>(`users/${userId}/actions/set-password`, data);
  }

  uniqueUserName(userName: string): Observable<User> {
    const params = new ApiParams();
    params.append('userName', userName);
    return this.apiService.doGet<User>(`users/find`, params);
  }
}
