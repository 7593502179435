import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PagedParam, PagedSearch, ApiParams } from '@outerlimitstech/ngx-app-core';
import { Observable } from 'rxjs';
import { Address } from 'bgcslib';
import { AddressValidationLocation } from '../models/address-validation-location';

@Injectable({
  providedIn: 'root'
})
export class AddressValidationService {

  constructor(protected apiService: ApiService) { }

  validateAddress(addressModel: Address): Observable<AddressValidationLocation> {
    const params = new ApiParams();
    params.append('line1', addressModel.line1);
    params.append('line2', addressModel.line2);

    if (addressModel.zip) {
      params.append('zip', addressModel.zip);
    } else if (addressModel.city && addressModel.state) {
      params.append('city', addressModel.city);
      params.append('state', addressModel.state);
    }

    return this.apiService.doGet(`/general/address/lookup`, params);
  }

}
