declare let dayjs: any;

export class DatePickerConfig {
  constructor(
    public minDate: Date,
    public maxDate: Date) { }
}

export class DatePickers {
  private now = dayjs();
  birthDate = new DatePickerConfig(this.now.subtract(20, 'years').toDate(), new Date());
  enrollDate = new DatePickerConfig(this.now.subtract(1, 'years').toDate(), new Date());
}
