
export class FileBase64Model {
  fileBase64: string;
  fileName: string;
  contentType: string;


  base64ToArrayBuffer(data) {
    const binaryString = window.atob(data);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    const arrBuff = this.base64ToArrayBuffer(b64Data);
    return new Blob([arrBuff], { type: contentType });
  }

  downloadFile(): void {
    const blob = this.b64toBlob(this.fileBase64, this.contentType, null);
    const link = document.createElement('a');
    const data = window.URL.createObjectURL(blob);
    link.href = data;
    link.setAttribute('type', 'hidden');
    link.download = `${this.fileName}`;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }

  constructor(data: any) {
    if (data == null) {
      return;
    }

    this.fileBase64 = data.fileBase64;
    this.fileName = data.fileName;
    this.contentType = data.contentType;
  }
}
