import { CodeValue } from './code-value';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

export class CodeValueOther extends CodeValue {
    other?: string;

    public static formGroupOther(fb: UntypedFormBuilder, validators?: Validators[]): UntypedFormGroup {
        return fb.group({
            id: [null, validators],
            other: [null],
        });
    }

    constructor(data?: any) {
        super(data);
        if (data == null) {
            return;
        }
        this.other = data.other;
    }
}

