<div class="container-fluid">

  <div class="row mt-5">
    <div class="col-12 col-md-8 col-lg-6 mx-auto mt-auto">
      <ng-content></ng-content>
    </div>
  </div>


</div>
