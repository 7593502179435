import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

export class CodeValue {
    id?: number;
    code?: string;
    name?: string;

    public static formGroup(fb: UntypedFormBuilder, validators?: Validators[]): UntypedFormGroup {
        return fb.group({
            id: [null, validators]
        });
    }

    constructor(data?: any) {
        if (data == null) {
            return;
        }
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
    }
}

