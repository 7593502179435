import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Name } from './name';
import { Address } from './address';
import { CodeValue } from './code-value';
import { ClientEligibility } from './client-eligibility';
import { ClientDiagnosed } from './client-diagnosed';
import { ClientContact } from './client-contact';
import { ClientEducation } from './client-education';
import { ClientProgram } from './client-program';
import { CodeValueOther } from './code-value-other';

export class Client {
  clientId?: number;
  dob?: Date;
  name = new Name();
  address = new Address();
  program = new ClientProgram();
  club = new CodeValue();
  gender = new CodeValueOther();
  livesWith = new CodeValueOther();
  race = new CodeValueOther();
  ethnicity = new CodeValueOther();
  county = new CodeValue();
  eligibility = new ClientEligibility();
  diagnosed = new ClientDiagnosed();
  contact = new ClientContact();
  education = new ClientEducation();
  twentyFirstCclcStudent?: boolean;
  twentyFirstCenturyScholar?: boolean;
  spanishSpeaking?: boolean;
  isArchived = false;
  learningExperience: string;
  onlinePasscode: string;
  years = new Array<number>();

  get currentYear(): number | null {
    return this.years?.length > 0 ? this.years[0] : null;
  }

  public static formGroup(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      name: Name.formGroup(fb, [Validators.required]),
      address: Address.formGroup(fb, [Validators.required], 'IN'),
      club: CodeValue.formGroup(fb, [Validators.required]),
      contact: ClientContact.formGroup(fb),
      diagnosed: ClientDiagnosed.formGroup(fb),
      education: ClientEducation.formGroup(fb),
      eligibility: ClientEligibility.formGroup(fb),
      dob: [null, [Validators.required]],
      spanishSpeaking: [false],
      gender: CodeValue.formGroup(fb, [Validators.required]),
      ethnicity: CodeValue.formGroup(fb, [Validators.required]),
      livesWith: CodeValueOther.formGroupOther(fb, [Validators.required]),
      race: CodeValueOther.formGroupOther(fb, [Validators.required]),
      program: ClientProgram.formGroup(fb),
      county: CodeValue.formGroup(fb, [Validators.required]),
      learningExperience: [null]
    });
  }

  public static formGroupSimple(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      name: Name.formGroup(fb, [Validators.required]),
      address: Address.formGroup(fb, [Validators.required]),
      club: CodeValue.formGroup(fb, [Validators.required]),
      contact: ClientContact.formGroup(fb),
      diagnosed: ClientDiagnosed.formGroupSimple(fb),
      education: ClientEducation.formGroupSimple(fb),
      eligibility: ClientEligibility.formGroup(fb),
      dob: [null, [Validators.required]],
      spanishSpeaking: [false],
      gender: CodeValue.formGroup(fb, [Validators.required]),
      ethnicity: CodeValue.formGroup(fb),
      livesWith: CodeValueOther.formGroupOther(fb),
      race: CodeValueOther.formGroupOther(fb),
      program: ClientProgram.formGroupSimple(fb),
      county: CodeValue.formGroup(fb),
      learningExperience: [null, [Validators.required]]
    });
  }

  constructor(data?: any) {

    this.clientId = data?.clientId;
    this.dob = data?.dob;
    this.twentyFirstCclcStudent = data?.twentyFirstCclcStudent;
    this.twentyFirstCenturyScholar = data?.twentyFirstCenturyScholar;
    this.spanishSpeaking = data?.spanishSpeaking;
    this.isArchived = data?.isArchived;
    this.onlinePasscode = data?.onlinePasscode;
    this.learningExperience = data?.learningExperience;
    this.years = data?.years;

    this.name = new Name(data?.name);
    this.address = new Address(data?.address);
    this.program = new ClientProgram(data?.program);
    this.club = new CodeValue(data?.club);
    this.gender = new CodeValueOther(data?.gender);
    this.livesWith = new CodeValueOther(data?.livesWith);
    this.race = new CodeValueOther(data?.race);
    this.ethnicity = new CodeValueOther(data?.ethnicity);
    this.county = new CodeValue(data?.county);
    this.eligibility = new ClientEligibility(data?.eligibility);
    this.diagnosed = new ClientDiagnosed(data?.diagnosed);
    this.contact = new ClientContact(data?.contact);
    this.education = new ClientEducation(data?.education);




  }
}



