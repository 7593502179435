import { ClientTest, DatePickerConfig } from 'bgcslib';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BaseViewComponent } from '@app/core/base-view.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { EditTestScoreResult } from '@app/core/models/edit-test-result';
import { OltHelperService } from '@outerlimitstech/ngx-app-core';


@Component({
  selector: 'app-edit-test-score-modal',
  templateUrl: './edit-test-score-modal.component.html',
  styleUrls: ['./edit-test-score-modal.component.scss']
})
export class EditTestScoreModalComponent extends BaseViewComponent implements OnInit {
  entryForm: UntypedFormGroup;
  settings: ClientTest;
  result = new EditTestScoreResult();


  constructor(
    private helperService: OltHelperService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
    public bsModalRef: BsModalRef) { super(); }

  ngOnInit() {
    this.pageConfig.loading = false;
    this.entryForm = ClientTest.formGroup(this.fb);
    const fgScore = this.entryForm.get('score');
    fgScore.setValidators([Validators.required, Validators.min(0), Validators.max(this.settings.test.maxPoints)]);
    fgScore.updateValueAndValidity();
    this.entryForm.patchValue(this.settings);
  }


  save(): void {
    if (!this.entryForm.valid) {
      this.entryForm.markAsDirty();
      this.entryForm.markAsTouched();
      this.entryForm.markAllAsTouched();
      this.helperService.growlErrorMessage('Please correct highlight errors');
      return;
    }

    this.result = new EditTestScoreResult(this.settings);
    this.result.result = true;
    this.result.score = this.entryForm.value.score;
    this.result.completed = this.entryForm.value.completed;
    this.bsModalRef.hide();
  }

}
