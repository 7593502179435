import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ClientTest } from 'bgcslib';

@Component({
  selector: 'app-client-test-view',
  templateUrl: './client-test-view.component.html',
  styleUrls: ['./client-test-view.component.scss'],
})
export class ClientTestViewComponent implements OnInit {
  @Input() clientTest: ClientTest;

  constructor() { }

  ngOnInit() {
  }

}
