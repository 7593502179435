import { Address } from 'bgcslib';

export class AddressValidation extends Address {

  public equals(compare: Address): boolean {
    return compare.line1 === this.line1 &&
      compare.line2 === this.line2 &&
      compare.zip === this.zip &&
      compare.city === this.city &&
      compare.state === this.state;
  }
}
