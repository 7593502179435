import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OltHttpConfigInterceptor, OltConfigServiceBase, OltAuthServiceBase } from '@outerlimitstech/ngx-app-core';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class GeneralInterceptor extends OltHttpConfigInterceptor implements HttpInterceptor {

  constructor(
    configService: OltConfigServiceBase,
    authService: OltAuthServiceBase,
    router: Router) {
    super(configService, authService, router);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return super.intercept(request, next);
  }

}
