import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { OltCacheService } from '@outerlimitstech/ngx-app-core';
import { ApiService } from './api.service';
import { CodeValueTypeEnum } from 'bgcslib';


@Injectable({
  providedIn: 'root'
})
export class CodeService {

  constructor(
    private apiService: ApiService,
    private cacheService: OltCacheService) { }

  public getCodes(codeValues: CodeValueTypeEnum[]): Observable<any> {
    const calls = [];
    codeValues.forEach(item => {
      const uri = `/codes/${item}`;
      const call = this.cacheService.get(uri, this.apiService.doGet(uri));
      calls.push(call);
    });
    return new Observable(observer => {
      if (calls.length === 0) {
        observer.next();
        observer.complete();
      } else {
        forkJoin(calls).subscribe(data => {
          observer.next(data);
          observer.complete();
        });
      }
    });
  }
}
