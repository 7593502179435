import { CodeValue } from './code-value';
export class Contract extends CodeValue {
  public start: Date;
  public end: Date;

  constructor(data?: any) {
    super(data);
    if (data == null) {
      return;
    }
    this.start = data.start;
    this.end = data.end;
  }
}
