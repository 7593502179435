import { CodeValue } from './code-value';
import { Name } from './name';

export class User {
  userId: number;
  userName: string;
  name = new Name();
  emailAddress: string;
  role = new CodeValue();
  club = new CodeValue();

  constructor(data?: any) {
    if (data == null) {
      return;
    }

    this.userName = data.userName;
    this.userId = data.userId;
    this.emailAddress = data.emailAddress;
    this.name = new Name(data.name);
    this.role = new CodeValue(data.role);
    this.club = new CodeValue(data.club);

  }
}

