import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { IFormGroupValidationState, OltBaseFormGroupComponent, OltUtility } from '@outerlimitstech/ngx-app-core';


@Component({
  selector: 'fg-button-radio-group, [formGroupName] fg-button-radio-group, [formGroup] fg-button-radio-group',
  templateUrl: './button-radio-group.component.html',
  styleUrls: ['./button-radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ButtonRadioGroupComponent extends OltBaseFormGroupComponent implements OnInit {
  @Input() controlName!: string;
  @Input() control!: FormControl;

  private _validationLabel: string | null = null;
  @Input()
  set validationLabel(value: string | null) {
    this._validationLabel = value;
  }
  get validationLabel(): string | null {
    if (this._validationLabel != null) {
      return this._validationLabel;
    }
    if (this.label && this.label.trim()) {
      return this.label;
    }
    return 'This Field';
  }


  constructor(private elRef: ElementRef) { super(); }

  ngOnInit(): void {

  }

  get formControl(): AbstractControl | null {
    const fg = this.formGroup;
    if (fg != null) {
      return fg.get(this.controlName);
    }
    return null;
  }

  get isValid(): boolean {
    return this.formControl?.dirty === true && this.formControl?.valid === true;
  }

  get isRequired(): boolean {
    const errors = this.formControl?.errors;
    if (errors != null) {
      // tslint:disable-next-line:no-string-literal
      return typeof errors['required'] !== 'undefined';
    }
    return false;
  }

  get showRequired(): boolean {
    if (this.isRequired) {
      if (this.formControl?.valid && this.formControl?.value != null) {
        return false;
      }
      return true;
    }
    return false;
  }

  get showSuccess(): boolean {
    if (this.formControl?.dirty && this.formControl?.valid && this.formControl?.value != null) {
      return true;
    }
    return false;
  }

  get showError(): boolean {
    return (this.formControl?.touched === true || this.formControl?.dirty === true) && this.formControl?.invalid === true;
  }

  get validationState(): IFormGroupValidationState {
    return {
      required: this.isRequired,
      invalid: this.formControl?.invalid === true,
      dirty: this.formControl?.dirty === true,
      touched: this.formControl?.touched === true,
      hasValue: this.formControl?.value != null
    }
  }

  get label(): string {
    const label = this.elRef.nativeElement.querySelector('.control-label');
    return (label && label.textContent && label.textContent.trim()) ?? 'Selection Required';
  }

  get firstError(): string | null {
    const errors = OltUtility.getFormValidationErrors(this.formControl, this.validationLabel);
    return errors?.length > 0 ? errors[0].message : null;
  }

}
