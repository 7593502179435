import { Component, OnInit } from '@angular/core';
import { BaseViewComponent, EditServiceResult } from '@app/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OltHelperService, OltUtility } from '@outerlimitstech/ngx-app-core';
import { ClientService, DatePickerConfig } from 'bgcslib';
declare let dayjs: any;

@Component({
  selector: 'app-edit-service-modal',
  templateUrl: './edit-service-modal.component.html',
  styleUrls: ['./edit-service-modal.component.scss']
})
export class EditServiceModalComponent extends BaseViewComponent implements OnInit {
  entryForm: UntypedFormGroup;
  settings: ClientService;
  result = new EditServiceResult();

  constructor(
    private helperService: OltHelperService,
    private fb: UntypedFormBuilder,
    public bsModalRef: BsModalRef) { super(); }

  ngOnInit() {
    this.entryForm = ClientService.formGroup(this.fb);
  }

  get endConfig(): DatePickerConfig {
    const start = this.entryForm.get('start').value;
    if (start != null) {
      const dt = dayjs(OltUtility.toDate(start));
      if (this.settings.service.minimumDays > 0) {
        return new DatePickerConfig(dt.add(this.settings.service.minimumDays, 'days').toDate(), new Date());
      }
      return new DatePickerConfig(dt.toDate(), new Date());
    }
    return this.settings.pickerConfig;
  }

  save(): void {
    if (!this.entryForm.valid) {
      this.entryForm.markAsDirty();
      this.entryForm.markAsTouched();
      this.entryForm.markAllAsTouched();
      this.helperService.growlErrorMessage('Please correct highlight errors');
      return;
    }
    const endDate = dayjs(this.entryForm.value.end).local().startOf('day');
    this.result = new EditServiceResult(this.settings);
    this.result.result = true;
    this.result.start = this.entryForm.value.start;
    this.result.end = endDate.toDate();
    this.bsModalRef.hide();
  }
}
