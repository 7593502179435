import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientTest, ClientService, Address } from 'bgcslib';
import { BsModalService, ModalOptions, BsModalRef } from 'ngx-bootstrap/modal';
import { EditTestScoreResult } from '@app/core/models/edit-test-result';
import { EditTestScoreModalComponent } from '../views/modals/edit-test-score-modal/edit-test-score-modal.component';
import { EditServiceModalComponent } from '../views/modals/edit-service-modal/edit-service-modal.component';
import { EditServiceResult } from '@app/core/models/edit-service-result';
import { AddressValidationResult, AddressValidationService, IModalComponent, ModalResult } from '@app/core';
import { AddressSuggestionModalComponent } from '../views/modals/address-suggestion-modal/address-suggestion-modal.component';



@Injectable()
export class ModalService {
  constructor(
    private addressValidationService: AddressValidationService,
    private modalService: BsModalService) { }

  private get defaultOptions(): ModalOptions {
    const options = new ModalOptions();
    options.animated = true;
    options.backdrop = 'static';
    options.ignoreBackdropClick = true;
    options.keyboard = false;
    return options;
  }

  public editTestScore(data: ClientTest): Observable<EditTestScoreResult> {
    let modalRef: BsModalRef;
    const config = this.defaultOptions;
    config.class = 'modal-sm';
    config.initialState = {
      settings: data
    };
    // modalRef.content.settings = data;
    return new Observable(observer => {
      modalRef = this.modalService.show(EditTestScoreModalComponent, config);
      this.modalService.onHide.subscribe(event => {
        observer.next(modalRef.content.result as EditTestScoreResult);
        observer.complete();
      });
    });
  }

  public editService(data: ClientService): Observable<EditServiceResult> {
    let modalRef: BsModalRef;
    const config = this.defaultOptions;
    config.class = 'modal-sm';
    config.initialState = {
      settings: data
    };
    // modalRef.content.settings = data;
    return new Observable(observer => {
      modalRef = this.modalService.show(EditServiceModalComponent, config);
      this.modalService.onHide.subscribe(event => {
        observer.next(modalRef.content.result as EditServiceResult);
        observer.complete();
      });
    });
  }

  public addressValidation(data: Address): Observable<AddressValidationResult> {
    let modalRef: BsModalRef;
    const config = this.defaultOptions;
    config.class = 'modal-lg';


    return new Observable(observer => {

      this.addressValidationService.validateAddress(data).subscribe(result => {
        if (result.locations != null && result.locations.length > 1) {
          config.initialState = {
            address: data,
            addressValidation: result
          };
          modalRef = this.modalService.show(AddressSuggestionModalComponent, config);
          this.modalService.onHide.subscribe(event => {
            observer.next(modalRef.content.result as AddressValidationResult);
            observer.complete();
          });
          return;
        }

        const validated = new AddressValidationResult();
        if (result.locations != null && result.locations.length === 1) {
          validated.result = true;
          validated.fromLocation(result.locations[0]);
        }

        observer.next(validated);
        observer.complete();

      });


    });



    // modalRef.content.settings = data;

  }



}
