import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PagedParam, Paged } from '@outerlimitstech/ngx-app-core';
import { Observable } from 'rxjs';
import { BillingDetail, BillingCount } from '../models/billing-detail';
import { BillingSummary } from '../models/billing-summary';
import { FileBase64Model } from 'bgcslib';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(
    protected apiService: ApiService
  ) { }

  get(billingId: number): Observable<BillingSummary> {
    return this.apiService.doGet(`/billings/${billingId}`);
  }

  getPending(pagingParameter: PagedParam): Observable<Paged<BillingDetail>> {
    return this.apiService.doGet(`/billings/pending/detail`, pagingParameter.toApiParams());
  }

  getPendingCounts(): Observable<BillingCount> {
    return this.apiService.doGet(`/billings/pending/counts`);
  }

  getDetail(billingId: number, pagingParameter: PagedParam): Observable<Paged<BillingDetail>> {
    return this.apiService.doGet(`/billings/${billingId}/detail`, pagingParameter.toApiParams());
  }

  create(): Observable<BillingSummary[]> {
    return this.apiService.doPost(`/billings`, null);
  }

  download(billingId: number): Observable<FileBase64Model> {
    return this.apiService.doGet(`/billings/${billingId}/download`);
  }
}
