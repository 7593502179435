import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { PageConfig } from '@outerlimitstech/ngx-app-core';
import { Subscription } from 'rxjs';


@Component({
  template: ''
})
export class BaseViewComponent implements OnInit, OnDestroy {

  public pageConfig = new PageConfig();
  private destroy$ = new Array<Subscription>();

  constructor() { }

  ngOnInit() {
    this.pageConfig.loading = true;
  }

  ngOnDestroy(): void {
    this.destroy$.forEach(sub$ => {
      sub$.unsubscribe();
    });
  }

  protected set unsub(sub$: Subscription) {
    this.destroy$.push(sub$);
  }

  setTouched(form: UntypedFormGroup | UntypedFormArray): void {
    Object.keys(form.controls).map((controlName) => {
      const control = form.get(controlName);
      if (control instanceof UntypedFormGroup) {
        this.setTouched(control);
      }
      if (control instanceof UntypedFormArray) {
        const formArray = control as UntypedFormArray;
        this.setTouched(formArray);
      }
      if (form.get(controlName).valid) {
        // if (form.get(controlName).value) {
        //     form.get(controlName).markAsTouched({ onlySelf: true });
        // }
      } else {
        form.get(controlName).markAsTouched({ onlySelf: true });
      }
    });
  }
}
