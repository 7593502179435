import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  public isPrinting = false;

  constructor(private router: Router) { }


  printTest(clientId: number, clientContractTestId: number) {
    this.isPrinting = true;
    this.router.navigate(['/',
      {
        outlets: {
          print: ['print', 'test', clientId, clientContractTestId]
        }
      }]);
  }

  onDocumentReady() {
    setTimeout(() => {
      window.print();
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null } }]);
    });
  }
}
