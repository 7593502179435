import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ClientService } from 'bgcslib';
import { ApiParams } from '@outerlimitstech/ngx-app-core';


@Injectable({
  providedIn: 'root'
})
export class ClientServiceService {

  constructor(
    protected apiService: ApiService
  ) { }

  getAll(clientId: number, year: number): Observable<ClientService[]> {
    const params = new ApiParams();
    params.append('year', year);
    return this.apiService.doGet(`/clients/${clientId}/services`, params);
  }
  // get(id: number): Observable<Array<ClientService>> {
  //   return this.apiService.doGet(`/clients/${id}/services`);
  // }

  save(clientId: number, year: number, data: ClientService): Observable<ClientService> {
    const params = new ApiParams();
    params.append('year', year);

    if (data.clientContractServiceId > 0) {
      return this.apiService.doPut(`/clients/${clientId}/services/${data.clientContractServiceId}`, data);
    }
    return this.apiService.doPost(`/clients/${clientId}/services`, data);
  }

  delete(clientId: number, clientContractServiceId: number): Observable<boolean> {
    return this.apiService.doDelete(`/clients/${clientId}/services/${clientContractServiceId}`);
  }

}
