import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PagedParam, PagedSearch } from '@outerlimitstech/ngx-app-core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientSearchService {

  constructor(protected apiService: ApiService) { }

  get(searchKey: string, pagingParameter: PagedParam): Observable<any> {
    return this.apiService.doGet(`clients/search/${searchKey}`, pagingParameter.toApiParams());
  }

  newEnrollment(data: any): Observable<PagedSearch<any, any>> {
    const pagingParameter = new PagedParam(1, 5);
    return this.apiService.doPost(`clients/search/new-enrollment`, data, pagingParameter.toApiParams());
  }

  getNewEnrollment(searchKey: string, pagingParameter: PagedParam): Observable<PagedSearch<any, any>> {
    return this.apiService.doGet(`clients/search/new-enrollment/${searchKey}`, pagingParameter.toApiParams());
  }

  getQuickSearch(searchKey: string, pagingParameter: PagedParam): Observable<any> {
    return this.apiService.doGet(`clients/search/quick/${searchKey}`, pagingParameter.toApiParams());
  }

  quickSearch(formData: any): Observable<string> {
    return this.apiService.doPost<string>(`clients/search/quick`, formData);
  }
}
