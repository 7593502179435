import { ClientService } from 'bgcslib';

export class EditServiceResult extends ClientService {
  result = false;

  constructor(data?: any) {
    super(data);
    if (data == null) {
      return;
    }
    this.result = data.result;
  }
}
