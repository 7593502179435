import { Name } from './name';
import { Test } from './test';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePickerConfig } from './datepickers-config';
import { GradeEquivalency } from './grade-equivalency';
declare let dayjs: any;

export class ClientTest {
  private now = dayjs();

  clientContractTestId: number;
  score: number;
  allowEntry: boolean;
  gradeEquivalency = new GradeEquivalency();
  completed: Date;
  scheduled: Date;
  pickerConfig = new DatePickerConfig(this.now.subtract(1, 'months').toDate(), new Date());
  test = new Test();
  clubName: string;
  clientName = new Name();

  public static formGroup(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      score: [null, [Validators.required]],
      completed: [null, [Validators.required]]
    });
  }

  constructor(data?: any) {
    if (data == null) {
      return;
    }
    this.clientContractTestId = data.clientContractTestId;
    this.score = data.score;
    this.allowEntry = data.allowEntry;
    this.gradeEquivalency = new GradeEquivalency(data.gradeEquivalency);
    this.completed = data.completed;
    this.scheduled = data.scheduled;
    this.test = new Test(data.test);
    this.clientName = new Name(data.clientName);
    this.clubName = data.clubName;

  }
}
