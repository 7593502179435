import { CodeValue } from './code-value';

export class Service extends CodeValue {
  typeCode: string;
  minimumDays: number;
  minimumAge?: number;
  maximumAge?: number;
  systemControlled: boolean;

  constructor(data?: any) {
    super(data);
    if (data == null) {
      return;
    }
    this.typeCode = data.typeCode;
    this.minimumDays = data.minimumDays;
    this.minimumAge = data.minimumAge;
    this.maximumAge = data.maximumAge;
    this.systemControlled = data.systemControlled;
  }
}
