import { Injectable } from '@angular/core';
import { IApiConfiguration } from '../interfaces';
import { Observable, catchError, of } from 'rxjs';
import { OltApiService, OltConfigServiceBase } from '@outerlimitstech/ngx-app-core';

@Injectable({
  providedIn: 'root'
})
export class ApiEnvironmentConfigService {

  private configuration$: Observable<IApiConfiguration> | null = null;

  private fallbackConfig: IApiConfiguration = {
    environment: 'unknown',
    productionDb: false,
    productionHub: false,
    showBanner: false
  };

  constructor(
    private apiService: OltApiService,
    private config: OltConfigServiceBase) { }

  public loadConfigurations(): Observable<IApiConfiguration> {
    const storageName = `${this.config.applicationName}-api-configuration`;

    if (this.configuration$ == null) {
      this.configuration$ = new Observable((observer) => {
        const data = sessionStorage.getItem(storageName);
        if (data != null) {
          observer.next(JSON.parse(data));
          observer.complete();
          return;
        }
        this.apiService.doGet<IApiConfiguration>(`configuration`)
          .pipe(catchError(error => of(this.fallbackConfig)))
          // .pipe(shareReplay(1));
          .subscribe(json => {
            sessionStorage.setItem(storageName, JSON.stringify(json));
            observer.next(json);
            observer.complete();
          });
      });
    }

    return this.configuration$;
  }
}
