
export class BillingSummary {
  billingId?: number;
  date: Date;
  constructor(data?: any) {
    // super(data);
    if (data == null) {
      return;
    }

    this.billingId = data.billingId;
    this.date = data.date;
  }
}
