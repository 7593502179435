import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { ClientTest } from 'bgcslib';
import { Router } from '@angular/router';
import { ApiParams } from '@outerlimitstech/ngx-app-core';


@Injectable({
  providedIn: 'root'
})
export class ClientTestService {


  constructor(
    protected apiService: ApiService
  ) { }

  getAll(clientId: number, year: number): Observable<ClientTest[]> {
    const params = new ApiParams();
    params.append('year', year);
    return this.apiService.doGet(`/clients/${clientId}/tests`, params);
  }

  // getAllCurrentYear(clientId: number): Observable<ClientTest[]> {
  //   return this.apiService.doGet(`/clients/${clientId}/tests`);
  // }

  get(clientId: number, clientTestId: number): Observable<ClientTest> {
    return this.apiService.doGet(`/clients/${clientId}/tests/${clientTestId}`);
  }

  save(clientId: number, year: number, data: ClientTest): Observable<ClientTest> {
    const params = new ApiParams();
    params.append('year', year);

    const postData = {
      testId: data.test.testId,
      score: data.score,
      completed: data.completed
    };

    if (data.clientContractTestId > 0) {
      return this.apiService.doPut(`/clients/${clientId}/tests/${data.clientContractTestId}`, postData, params);
    }
    return this.apiService.doPost(`/clients/${clientId}/tests`, postData, params);
  }

  delete(clientId: number, clientTestId: number): Observable<boolean> {
    return this.apiService.doDelete(`/clients/${clientId}/tests/${clientTestId}`);
  }

}
