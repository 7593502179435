import { Address } from 'bgcslib';
import { ValidationLocationAddress } from './address-validation-location';

export class AddressValidationResult extends Address {
  result = false;
  countyId: number;

  fromLocation(data: ValidationLocationAddress) {
    this.line1 = data.line1;
    this.line2 = data.line2;
    this.city = data.city;
    this.state = data.state;
    this.zip = data.zip;
    this.countyId = data.countyId;
  }

  constructor(data?: any) {
    super(data);
    if (data == null) {
      return;
    }
    this.result = data.result;
    this.countyId = data.countyId;
  }
}
