import { Component } from '@angular/core';
import { ApiEnvironmentConfigService } from '../../services';

@Component({
  selector: 'bgcs-environment-footer',
  templateUrl: './environment-footer.component.html',
  styleUrls: ['./environment-footer.component.scss']
})
export class EnvironmentFooterComponent {
  showFooter = false;
  productionDb = false;
  productionHub = false;
  environment = 'Unknown';

  constructor(
    private configurationService: ApiEnvironmentConfigService,
  ) { }
  ngOnInit() {

    this.configurationService.loadConfigurations()
      .subscribe(config => {
        this.environment = config.environment.toLowerCase();
        this.showFooter = config.showBanner;
        this.productionDb = config.productionDb;
        this.productionHub = config.productionHub;
      });
  }

  get source(): string | null {
    if (this.productionDb === true && this.productionHub === true) {
      return 'Production Database and Hub';
    }

    if (this.productionDb === true) {
      return 'Production Database';
    }
    if (this.productionHub === true) {
      return 'Production Hub';
    }
    return null;
  }

  get style(): string {
    if (this.productionDb === true || this.productionHub) {
      return 'production';
    }
    return this.environment;
  }

}
