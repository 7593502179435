import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { OltHelperService } from '@outerlimitstech/ngx-app-core';
import { UrlTree } from '@angular/router';
import { CONSTANTS } from '../models/constants';
import { AppSettingsService } from './app-settings.service';
import { BaseConfigService } from 'bgcslib';

  
@Injectable({
  providedIn: 'root'
})
export class ConfigService implements BaseConfigService {
  hostUrl = new URL(this.helperService.resolveUrl('~/'));

  constructor(
    private helperService: OltHelperService,
    private appsettings: AppSettingsService,  
  ) { }

  

  get applicationTitle(): string {
    return CONSTANTS.Application.Title;
  }

  get apiRootUrl(): string {
    //console.log('hostSetting', this.appsettings.settings?.hosts?.find(p => p.host == this.hostUrl.hostname), this.appsettings.settings?.hosts);
    const apiRoot = this.appsettings.settings?.hosts?.find(p => p.host == this.hostUrl.hostname)?.apiEndpoint ?? environment.apiRoot;
    if (apiRoot.startsWith('~/')) {
      return apiRoot.replace('~/', `${this.helperService.baseUrl}/`);
    }
    return apiRoot;
  }

  get applicationName(): string {
    return CONSTANTS.Application.Name;
  }

  get supportEmail(): string {
    return CONSTANTS.Application.SupportEmail;
  }

  get serverLoggingUrl(): string {
    return `${this.apiRootUrl}/log`;
  }

  get signalRUrl(): string {
    return `${this.apiRootUrl.substring(0, this.apiRootUrl.length - 4)}/hubs`;
  }

  get permissionDeniedRoute(): string | UrlTree {
    return '/permission-denied';
  }

  get accessDeniedRoute(): string | UrlTree {
    return '/access-denied';
  }

  get applicationErrorRoute(): string | UrlTree {
    return '/application-error';
  }

  get loginRoute(): string | UrlTree {
    return '/account/login';
  }

  apiUrl(url: string): string {
    if (url.startsWith('http')) {
      return url;
    }
    if (url.startsWith('/') || this.apiRootUrl.endsWith('/')) {
      return `${this.apiRootUrl}${url}`;
    }
    return `${this.apiRootUrl}/${url}`;
  }

  get isProduction(): boolean {
    return environment.production === true;
  }



}
