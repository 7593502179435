<div class="row mt-3" [class.olt-loading-page]="loading">
  <div class="col-12 d-md-none d-sm-block d-md-block d-lg-none">
    <ul class="nav nav-pills-tab nav-pills flex-column rounded">
      <li class="nav-item">
        <a class="nav-link tab-toggle" href="javascript:void(0);" (click)="toggleCollapse()" aria-expanded="false">
          <i [ngClass]="toggleIcon"></i> {{ toggleLabel }}
          <div class="float-right"><i class="fas fa-bars"></i></div>
        </a>
      </li>
      <div [collapse]="collapsed" [isAnimated]="true">
        <ng-container *ngFor="let tab of tabs">

          <li class="nav-item" *ngIf="tab.visible && !activeUrl(tab)">
            <a class="nav-link" href="javascript:void(0);" (click)="tabNavigate(tab)">
              <i [ngClass]="tab.icon" aria-hidden="true"></i> {{ tab.text }}
            </a>
          </li>

        </ng-container>
      </div>

    </ul>
  </div>


  <div class="col-12 d-none d-md-none d-lg-block d-xl-block">
    <ul class="nav nav-tabs nav-fill">
      <ng-container *ngFor="let tab of tabs">
        <li class="nav-item h4" *ngIf="tab.visible">
          <a class="nav-link" role="tab" [routerLink]="[tab.uri]" queryParamsHandling="preserve"
            routerLinkActive="active">
            <i [ngClass]="tab.icon"></i> {{ tab.text }}
          </a>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
<div class="olt-tabs-body">
  <router-outlet></router-outlet>
</div>
