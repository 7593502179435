import { Component, ElementRef, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, FormGroupName, ControlContainer, AbstractControl, FormGroupDirective } from '@angular/forms';

const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
};

@Component({
  selector: 'app-form-group, [formGroupName] app-form-group, [formGroup] app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss']
})
export class FormGroupComponent implements OnInit, AfterViewInit {
  @Input()
  set validationLabel(value: string | null) {
    this._validationLabel = value;
  }
  get validationLabel(): string {
    if (this._validationLabel != null) {
      return this._validationLabel;
    }
    if (this.label && this.label.trim() && this.label !== this.controlLabelMissing) {
      return this.label;
    }
    return 'This Field';
  }

  @Input() srOnly = false;

  // tslint:disable-next-line:variable-name
  private _validationLabel: string = null;
  private controlLabelMissing = '<span class="control-label">Label Here</span> missing';

  private focused: boolean;
  @ViewChild('control', { static: true }) control: ElementRef;

  private name: string = 'formGroup_' + guid().substring(0, 8);

  constructor(private controlContainer: ControlContainer, private elRef: ElementRef) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const ele = this.inputElement;
    if (ele && this.controlId && ele.getAttribute('id') == null && ele.getAttribute('name') == null) {
      ele.id = this.controlId;
    }
  }


  get inputElement(): Element | null {
    const span = this.control.nativeElement as HTMLSpanElement;
    let ele = span.firstElementChild;
    if (ele == null) {
      ele = this.elRef.nativeElement.querySelector('.form-control');
    }
    return ele;
  }

  get controlId(): string {
    const ele = this.inputElement;
    if (ele) {
      const id = ele.getAttribute('id');
      if (id) {
        return id;
      }
      const name = ele.getAttribute('name');
      if (name) {
        return name;
      }

      // This causes problems inside a Form Array;
      // const controlName = span.firstElementChild.getAttribute('formControlName');
      // if (controlName) {
      //   return controlName;
      // }
    }

    return this.name;
  }

  get formControlName(): string {
    const span = this.control.nativeElement as HTMLSpanElement;
    const controlName = span.firstElementChild.getAttribute('formControlName');
    if (controlName == null) {
      return this.elRef.nativeElement.querySelector('.form-control').getAttribute('formControlName');
    }
    return controlName;
  }

  get formGroup(): UntypedFormGroup | AbstractControl {
    if (this.controlContainer instanceof FormGroupName) {
      return (this.controlContainer as FormGroupName).control;
    }

    if (this.controlContainer instanceof UntypedFormGroup) {
      return this.controlContainer as UntypedFormGroup;
    }

    if (this.controlContainer instanceof FormGroupDirective) {
      return (this.controlContainer as FormGroupDirective).control;
    }

    return this.controlContainer.control;
  }

  get formControl(): AbstractControl {
    return this.formGroup.get(this.formControlName);
  }

  get isValid() {
    return this.formControl.dirty && this.formControl.valid;
  }

  get isTouched(): boolean {
    return this.formControl.touched;
  }

  get isRequired(): boolean {
    const errors = this.formControl.errors;
    if (errors) {
      // tslint:disable-next-line:no-string-literal
      return typeof errors['required'] !== 'undefined';
    }
    return false;
  }

  get showRequiredIndicator(): boolean {
    if (this.isRequired) {
      if (this.formControl.valid && this.formControl.value) {
        return false;
      }
      return true;
    }
    return false;
  }

  get showSuccessIndicator(): boolean {
    if (this.formControl.valid && this.formControl.value) {
      return true;
    }
    return (this.formControl.touched || this.formControl.dirty) && this.formControl.valid;
  }

  get showErrorIndicator(): boolean {
    return (this.formControl.dirty || this.formControl.touched) && this.formControl.invalid;
  }

  get isFocused(): boolean {
    return this.focused;
  }

  set isFocused(focused: boolean) {
    this.focused = focused;
  }



  get label(): string {
    const label = this.elRef.nativeElement.querySelector('.control-label');
    return (label && label.textContent && label.textContent.trim()) || this.controlLabelMissing;
  }

  public getFirstError() {
    const errors = this.formControl.errors;
    let first: string;
    for (const err in errors) {
      if (errors.hasOwnProperty(err) && errors[err]) {
        first = err;
        break;
      }
    }

    if (first) {
      const displayLabel = this.validationLabel;
      let msg = 'invalid';

      switch (first) {
        case 'minlength':
          msg = 'too short';
          break;
        case 'maxlength':
          msg = 'too long';
          break;
        case 'required':
          msg = 'required';
          break;
        case 'min':
          msg = 'too low';
          break;
        case 'max':
          msg = 'too high';
          break;
        case 'email':
          msg = 'invalid';
          break;
        default:
          if (this.formControl.errors[first] && this.formControl.errors[first].message) {
            msg = this.formControl.errors[first].message;
          }
          break;
      }
      return `${displayLabel} is ${msg}.`;
    }
  }

}
