import { CodeValue } from './code-value';


export class Test {
    testId: number;
    name: string;
    type: CodeValue;
    isPostTest: boolean;
    maxPoints: number;

    // public static formGroup(fb: FormBuilder): FormGroup {
    //     return fb.group({
    //         start: [null, [Validators.required]],
    //         end: [null, [Validators.required]]
    //     });
    // }

    constructor(data?: any) {
        if (data == null) {
            return;
        }
        this.testId = data.testId;
        this.name = data.name;
        this.isPostTest = data.isPostTest;
        this.maxPoints = data.maxPoints;
        this.type = new CodeValue(data.type);
    }
}

