import { ConfirmModal, ModalTypeEnum } from '@outerlimitstech/ngx-app-core';

export class ConfirmDeleteModal extends ConfirmModal {
  constructor(
    message: string,
    title?: string
  ) {
    super(
      message,
      title || 'Delete Confirmation',
      ModalTypeEnum.Danger);
  }
}
