import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { OltApiService, ApiParams } from '@outerlimitstech/ngx-app-core';
import { FileBase64Model } from 'bgcslib';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(
    protected apiService: OltApiService
  ) { }


  download(reportType: string, params?: ApiParams): Observable<FileBase64Model> {
    return this.apiService.doGet(`/reports/${reportType}/download`, params);
  }
}
