import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';

export class Name {
    prefix?: string;
    first?: string;
    middle?: string;
    last?: string;
    suffix?: string;

    public static formGroup(fb: UntypedFormBuilder, validators?: Validators[]): UntypedFormGroup {
        return fb.group({
            first: [null, validators],
            middle: [null],
            last: [null, validators],
            suffix: [null],
        });
    }

    constructor(data?: any) {
        if (data == null) {
            return;
        }
        this.prefix = data.prefix;
        this.first = data.first;
        this.middle = data.middle;
        this.last = data.last;
        this.suffix = data.suffix;
    }
}
