import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

export class OltTabDefinition {
  text: string;
  uri: string;
  icon: string;
  visible = true;
  fullUrl: string;

  constructor(data?: any) {
    if (data == null) {
      return;
    }
    this.text = data.text;
    this.uri = data.uri;
    this.icon = data.icon;
    this.fullUrl = data.fullUrl;

    if (data.visible === false) {
      this.visible = false;
    }

  }
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'bgcs-responsive-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit, OnChanges {
  @Input() tabs: Array<OltTabDefinition>;
  @Input() loading: boolean;
  collapsed = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tabs.currentValue != null) {
      this.tabs.forEach(tab => {
        tab.fullUrl = `${this.getResolvedUrl(this.route.snapshot)}/${tab.uri}`;
      });
    }
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return '/' + route.pathFromRoot
      .map(v => v.url.map(segment => segment.toString()).join('/'))
      .filter(t => t != null && t.length > 0)
      .join('/');
  }

  getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    return '/' + route.pathFromRoot
      .filter(v => v.routeConfig)
      // tslint:disable-next-line: no-non-null-assertion
      .map(v => v.routeConfig!.path)
      .filter(t => t != null && t.length > 0)
      .join('/');
  }

  get toggleLabel(): string {
    const tab = this.tabs.find(p => p.fullUrl === this.router.url);
    if (tab == null) {
      return 'Tabs';
    }
    return tab.text;
  }

  get toggleIcon(): string {
    const tab = this.tabs.find(p => p.fullUrl === this.router.url);
    if (tab == null) {
      return 'fas fa-anchor';
    }
    return tab.icon;
  }

  activeUrl(tab: OltTabDefinition): boolean {
    return this.router.url === tab.fullUrl;
  }

  tabNavigate(tab: OltTabDefinition) {
    this.router.navigate([tab.fullUrl], { queryParamsHandling: 'preserve' });
    this.collapsed = true;
  }

  toggleCollapse() {
    this.collapsed = !this.collapsed;
  }

}
