import { inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { OltAuthServiceBase, OltConfigServiceBase } from '@outerlimitstech/ngx-app-core';
import { ISignalrMessage } from '../models/signalr-message';
import { BaseConfigService } from './base-config.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  private connection!: signalR.HubConnection;
  messageBusMessage = new BehaviorSubject<ISignalrMessage | null>(null);

  private authService = inject(OltAuthServiceBase);
  private toastr = inject(ToastrService);
  private configService = inject(BaseConfigService);
  
  constructor() {
    this.initHub();
  }


  private initHub() {
    // const options: signalR.IHttpConnectionOptions = {
    //   // httpClient: new CustomHttpClient(tokenType, token, signalR.NullLogger.instance),
    //   // accessTokenFactory: () => {
    //   //   return this.authService?.token ?? '';
    //   //  }
    // };

    const url = `${this.configService.signalRUrl}/broadcast`;  //remove last 3 characters of the apiUrl

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        headers: {
          "Authorization": this.authService?.token != null ? `Bearer ${this.authService?.token}` : null
        }
      })
      // .withUrl(url)
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    this.configureClients();

    this.connection
      .start()
      // .then(() => {
      //   console.log(`SignalR connection success! connectionId: ${this.connection.connectionId} `);
      //   // resolve();
      // })
      .catch((error) => {
        console.log(`SignalR connection error: ${error}`);
        // reject();
      });
  }


  private configureClients(): void {
    this.connection.on('messagebus', (message: ISignalrMessage) => {
      console.log('hub.messagebus', message);
      this.messageBusMessage.next(message);
    });
  }

  public displayGrowlMessage(message: ISignalrMessage | null): void {
    if (message == null) {
      return;
    }

    const showAlert = message?.user?.username !== this.authService.username;
    if (showAlert === true && message?.alertMessage != null) {
      this.toastr.info(`- by ${message?.user?.fullName}`, message.alertMessage, { positionClass: 'toast-top-right', closeButton: true });
    }
  }


}
