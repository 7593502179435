import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Service } from './service';
import { DatePickerConfig } from './datepickers-config';

export class ClientServiceIneligible {
  reason: string;
  constructor(data?: any) {
    if (data == null) {
      return;
    }
    this.reason = data.reason;
  }
}
export class ClientService {

  clientContractServiceId?: number;
  service = new Service();
  start: Date;
  end: Date;
  pickerConfig = new DatePickerConfig(new Date(), new Date());
  allowEntry: boolean;
  ineligible = new ClientServiceIneligible();

  public static formGroup(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      start: [null, [Validators.required]],
      end: [null, [Validators.required]]
    });
  }

  constructor(data?: any) {
    if (data == null) {
      return;
    }
    this.clientContractServiceId = data.clientContractServiceId;
    this.start = data.start;
    this.end = data.end;
    this.allowEntry = data.allowEntry;
    this.allowEntry = data.allowEntry;
    this.service = new Service(data.service);
    this.ineligible = new ClientServiceIneligible(data.ineligible);
  }
}
