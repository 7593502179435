import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageConfig } from '@outerlimitstech/ngx-app-core';
import { Subscription } from 'rxjs';


@Component({
  template: ''
})
export class BgcsBaseViewComponent implements OnInit, OnDestroy {

  public pageConfig = new PageConfig();
  private destroy$ = new Array<Subscription>();

  constructor() { }

  ngOnInit() {
    this.pageConfig.loading = true;
  }

  ngOnDestroy(): void {
    this.destroy$.forEach(sub$ => {
      sub$.unsubscribe();
    });
  }

  protected set unsub(sub$: Subscription) {
    this.destroy$.push(sub$);
  }
}
