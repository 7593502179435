import { UserService } from './../services/user.service';
import { AbstractControl, FormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {

  private static isInt(n): boolean {
    return Number(n) === n && n % 1 === 0;
  }

  private static isFloat(n): boolean {
    return Number(n) === n && n % 1 !== 0;
  }

  /**
   * Match two controls if they are the same
   * @param firstControlName
   * @param secondControlName
   * @returns {(AC: AbstractControl) => any}
   * @constructor
   */
  static Match(firstControlName, secondControlName, errorMsg) {
    return (AC: AbstractControl) => {
      const firstControlValue = AC.get(firstControlName).value; // to get value in input tag
      const secondControlValue = AC.get(secondControlName).value; // to get value in input tag
      // tslint:disable-next-line:triple-equals
      if (firstControlValue != secondControlValue) {
        AC.get(secondControlName).setErrors({ matchFields: { validationMsg: errorMsg } });
      } else {
        return null;
      }
    };
  }

  static PasswordStrengthValidator(control: UntypedFormControl) {

    const hasNumber = /\d/.test(control.value);
    // const hasUpper = /[A-Z]/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    // const valid = hasNumber && hasUpper && hasLower;
    const valid = hasNumber && hasLower;
    if (!valid) {
      return { passwordStrength: { validationMsg: 'does not match policy' } };
    }
    return null;
  }

  // static GreaterThanEqValidator() {
  //   return (control: AbstractControl) => {
  //     const upper: number = +control.value;
  //     // const lower: number = +lowerControl.value;
  //     const lower = 1;
  //     console.log(upper, lower);
  //     if (lower <= upper) {
  //           return { greaterThanEq: { validationMsg: 'does not match policy' } };
  //     } else {
  //       return null;
  //     }
  //   };
  // }

  static GreaterThanEqValidator(lowerControlName, upperControlName) {
    return (group: UntypedFormGroup) => {
      const lowerControl = group.get(lowerControlName);
      const upperControl = group.get(upperControlName);
      const lower: number = +lowerControl.value;
      const upper: number = +upperControl.value;
      const valid = lower <= upper;
      if (!valid) {
        const msg = lower >= 0 ? `must be >= ${lower}` : 'is invalid';
        group.get(upperControlName).setErrors({ greaterThanEq: { validationMsg: msg } });
      } else {
        return null;
      }
    };
  }


  static UniqueUserNameValidator(userService: UserService, userId?: number) {
    return (control: AbstractControl) => {

      return new Promise(resolve => {
        const value = control.value;
        if (value) {
          return userService.uniqueUserName(value).subscribe(json => {
            if (json != null && json.userId !== userId) {
              resolve({ uniqueUserName: { validationMsg: 'already exists' } });
            } else {
              resolve(null);
            }
          });
        } else {
          resolve(null);
        }
      });
    };
  }
}
