import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-container',
  templateUrl: './content-container.component.html',
  styleUrls: ['./content-container.component.scss']
})
export class ContentContainerComponent {
  @Input() centered = false;
  @Input() columnClass = 'col-12';
  @Input() contentClass = 'card-body';

  get contentContainerClass(): string {
    return this.centered ? `mx-auto ${this.columnClass}` : this.columnClass;
  }

  get fluidContainerClass(): string | null {
    return this.centered ? 'flex-row align-items-center' : null;
  }


}
