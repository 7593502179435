<ng-container *ngIf="showFooter">
    <div class="mt-5 mb-5"></div>
    <div class="mt-5 mb-5"></div>
    <footer class="footer fixed-bottom d-none d-md-block" [ngClass]="style">
      <div class="container-fluid">
        <div class="d-flex justify-content-center mt-2 mb-2">
          {{ environment | uppercase }} ENVIRONMENT
          <div *ngIf="source != null" class="ml-2">
            [ {{ source }} ]
          </div>
        </div>
      </div>
    </footer>
    <footer class="footer d-md-none py-3" [ngClass]="style">
      <div class="container-fluid">
        <div class="d-flex justify-content-center mt-1 mb-1">
          {{ environment | uppercase }} ENVIRONMENT
          <div *ngIf="source != null" class="ml-2">
            [ {{ source }} ]
          </div>
        </div>
      </div>
    </footer>
  </ng-container>