
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbsModule } from '@exalif/ngx-breadcrumbs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { OltCoreModule } from '@outerlimitstech/ngx-app-core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { OltUiSwitchModule } from '@outerlimitstech/ngx-ui-switch';
import { OltNgxTableModule } from '@outerlimitstech/ngx-bootstrap/html-table';
import { ContentContainerComponent } from './components/content-container/content-container.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { BgcslibModule } from 'bgcslib';
import { EditTestScoreModalComponent } from './views/modals/edit-test-score-modal/edit-test-score-modal.component';
import { EditServiceModalComponent } from './views/modals/edit-service-modal/edit-service-modal.component';
import { ContentContainerCenteredComponent } from './components/content-container-centered/content-container-centered.component';
import { AddressSuggestionModalComponent } from './views/modals/address-suggestion-modal/address-suggestion-modal.component';
import { PasswordStrengthComponent } from './components/password-strength/password-strength.component';
import { ClientTestViewComponent } from './components/client-test-view/client-test-view.component';
import { FormatServiceDatesPipe } from './pipes/format-service-dates.pipe';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { TabComponent } from './components/tab/tab.component';
import { RouterModule } from '@angular/router';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ButtonRadioGroupComponent } from './components/button-radio-group/button-radio-group.component';



export function getBsDatepickerConfig(): BsDatepickerConfig {
    return Object.assign(new BsDatepickerConfig(), {
        dateInputFormat: 'M/D/YYYY',
        containerClass: 'theme-default',
        showWeekNumbers: false
    });
}


@NgModule({
    declarations: [
        ContentContainerComponent,
        ComingSoonComponent,
        EditTestScoreModalComponent,
        EditServiceModalComponent,
        ContentContainerCenteredComponent,
        AddressSuggestionModalComponent,
        PasswordStrengthComponent,
        ClientTestViewComponent,
        FormatServiceDatesPipe,
        FormGroupComponent,
        TabComponent,
        ButtonRadioGroupComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPermissionsModule,
        RouterModule,
        BreadcrumbsModule.forRoot(),
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        CollapseModule.forRoot(),
        ButtonsModule.forRoot(),
        OltCoreModule,
        BgcslibModule,
        OltNgxTableModule,
        OltUiSwitchModule.forRoot({
            // size: 'small',
            color: 'rgb(0, 189, 99)',
            // switchColor: '#80FFA2',
            defaultBgColor: 'red',
            // defaultBoColor: '#476EFF',
            checkedLabel: 'Yes',
            uncheckedLabel: 'No'
        }),
        LoggerModule.forRoot({
            serverLoggingUrl: `/api/logs`,
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.ERROR,
        }),
    ],
    exports: [
        FormsModule,
        ReactiveFormsModule,
        NgxPermissionsModule,
        BreadcrumbsModule,
        ModalModule,
        BsDatepickerModule,
        BsDropdownModule,
        CollapseModule,
        ButtonsModule,
        OltCoreModule,
        BgcslibModule,
        OltUiSwitchModule,
        OltNgxTableModule,
        ContentContainerComponent,
        ComingSoonComponent,
        ContentContainerCenteredComponent,
        PasswordStrengthComponent,
        ClientTestViewComponent,
        FormatServiceDatesPipe,
        FormGroupComponent,
        TabComponent,
        ButtonRadioGroupComponent,
    ],
    providers: [
        {
            provide: BsDatepickerConfig,
            useFactory: getBsDatepickerConfig
        }
    ]
})
export class SharedModule { }
