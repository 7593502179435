import { CodeValue, CodeValueTypeEnum } from 'bgcslib';
import { Observable } from 'rxjs';
import { CodeService } from '../services';

export class EditClientCodeValues {
  counties = new Array<CodeValue>();
  clubs = new Array<CodeValue>();
  genders = new Array<CodeValue>();
  nameSuffixes = new Array<CodeValue>();
  states = new Array<CodeValue>();
  livesWithTypes = new Array<CodeValue>();
  race = new Array<CodeValue>();
  ethnicities = new Array<CodeValue>();
  gradeLevels = new Array<CodeValue>();

  load(codeService: CodeService): Observable<boolean> {
    const types = [
      CodeValueTypeEnum.genders,
      CodeValueTypeEnum.nameSuffixes,
      CodeValueTypeEnum.states,
      CodeValueTypeEnum.livesWithTypes,
      CodeValueTypeEnum.race,
      CodeValueTypeEnum.ethnicities,
      CodeValueTypeEnum.gradeLevels,
      CodeValueTypeEnum.clubs,
      CodeValueTypeEnum.counties,
    ];

    return new Observable(observer => {
      codeService.getCodes(types).subscribe(values => {
        const [genders, nameSuffixes, states, livesWithTypes, race, ethnicities, gradeLevels, clubs, counties] = values as Array<any>;
        this.genders = genders;
        this.nameSuffixes = nameSuffixes;
        this.nameSuffixes = nameSuffixes;
        this.states = states;
        this.livesWithTypes = livesWithTypes;
        this.race = race;
        this.ethnicities = ethnicities;
        this.gradeLevels = gradeLevels;
        this.clubs = clubs;
        this.counties = counties;
        observer.next(true);
        observer.complete();
      });
    });

  }
}
