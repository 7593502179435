import { CodeValue } from './code-value';
import { ClientStruggleWith } from './client-struggle-with';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
export class ClientEducation {
  nameOfSchool: string;
  gradeLevel = new CodeValue();
  struggleWith = new ClientStruggleWith();
  // takeIstep: boolean;
  inSpecialEd: boolean;
  twentyFirstCclcStudent: boolean;
  twentyFirstCenturyScholar: boolean;


  public static formGroup(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      nameOfSchool: [null, [Validators.required]],
      gradeLevel: CodeValue.formGroup(fb, [Validators.required]),
      struggleWith: ClientStruggleWith.formGroup(fb, [Validators.required]),
      // takeIstep: [null, [Validators.required]],
      inSpecialEd: [null, [Validators.required]],
      twentyFirstCclcStudent: [null, [Validators.required]],
      twentyFirstCenturyScholar: [null, [Validators.required]],
    });
  }

  public static formGroupSimple(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      nameOfSchool: [null],
      gradeLevel: CodeValue.formGroup(fb, [Validators.required]),
      struggleWith: ClientStruggleWith.formGroup(fb),
      // takeIstep: [null],
      inSpecialEd: [null],
      twentyFirstCclcStudent: [null],
      twentyFirstCenturyScholar: [null],
    });
  }

  constructor(data?: any) {
    if (data == null) {
      return;
    }

    this.nameOfSchool = data.nameOfSchool;
    // this.takeIstep = data.takeIstep;
    this.inSpecialEd = data.inSpecialEd;
    this.twentyFirstCclcStudent = data.twentyFirstCclcStudent;
    this.twentyFirstCenturyScholar = data.twentyFirstCenturyScholar;

    this.gradeLevel = new CodeValue(data.gradeLevel);
    this.struggleWith = new ClientStruggleWith(data.struggleWith);

  }
}
