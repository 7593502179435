import { inject, Injectable } from '@angular/core';
import { OltApiService } from '@outerlimitstech/ngx-app-core';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { BaseStorageService } from './base-storage.service';

export const initializeFeatureFlagFactory = (service: FeatureFlagService) => () => service.initializeFlags();

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  private storageService = inject(BaseStorageService);
  protected apiService = inject(OltApiService);


  initializeFlags(): Observable<boolean> {
    return this.apiService.doGet<any>('/general/feature-flags')
     .pipe(
        switchMap((response) => {
          this.storageService.featureFlags = response.featureFlag;
          return of(true);
        }),
        catchError((error) => {
          this.storageService.featureFlags = null;
          console.error(error);
          return of(false);
        })
    );
  }


  isFeatureEnabled(key: string) {
    const flags = this.storageService.featureFlags ?? {};
    return flags[key] === true;
  }


}
