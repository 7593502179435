import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
export class Phone {
  number: string;
  ext?: string;

  public static formGroup(fb: UntypedFormBuilder, required?: boolean): UntypedFormGroup {
    const validators = new Array<Validators>();
    if (required === true) {
      validators.push(Validators.required);
    }
    return fb.group({
      number: [null, validators],
      ext: [null],
    });
  }

  constructor(data?: any) {
    if (data == null) {
      return;
    }
    this.number = data.number;
    this.ext = data.ext;
  }
}
