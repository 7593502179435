import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';


export class ClientEligibility {
    tanf: boolean;
    foodStamps: boolean;
    medicaidHoosierHealthwise: boolean;
    freeLunchProgram: boolean;
    reducedLunchProgram: boolean;
    publicHousing: boolean;
    atOrBelowAnnualIncome: boolean;
    none?: boolean;


    public static formGroup(fb: UntypedFormBuilder): UntypedFormGroup {
        return fb.group({
            tanf: [false],
            foodStamps: [false],
            medicaidHoosierHealthwise: [false],
            freeLunchProgram: [false],
            reducedLunchProgram: [false],
            publicHousing: [false],
            atOrBelowAnnualIncome: [false],
            none: [false],
        });
    }

    constructor(data?: any) {
        if (data == null) {
            return;
        }

        this.tanf = data.tanf;
        this.foodStamps = data.foodStamps;
        this.medicaidHoosierHealthwise = data.medicaidHoosierHealthwise;
        this.freeLunchProgram = data.freeLunchProgram;
        this.reducedLunchProgram = data.reducedLunchProgram;
        this.publicHousing = data.publicHousing;
        this.atOrBelowAnnualIncome = data.atOrBelowAnnualIncome;
        this.none = data.none;
    }
}
