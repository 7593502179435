import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Phone } from './phone';

export class ClientContact {
  email?: string;
  phone = new Phone();
  alternatePhone = new Phone();
  contactEmailOptIn?: boolean;

  public static formGroup(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      email: [null, [Validators.email]],
      phone: Phone.formGroup(fb, false),
      alternatePhone: Phone.formGroup(fb, false),
      contactEmailOptIn: [false],
    });
  }


  constructor(data?: any) {
    this.email = data?.email;
    this.contactEmailOptIn = data?.contactEmailOptIn;
    this.phone = new Phone(data?.phone);
    this.alternatePhone = new Phone(data?.alternatePhone);
  }
}
