import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IOltAppSettingResult, OltHelperService, OltAppSettingsServiceBase } from '@outerlimitstech/ngx-app-core';
import { IAppSettings } from 'bgcslib';
import { FALL_BACK_CONFIG } from '../models/constants';

export const appSettingsFactory = (appSettingService: AppSettingsService) => () => appSettingService.loadAppConfig();

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService extends OltAppSettingsServiceBase<IAppSettings> {

  constructor(helperService: OltHelperService, http: HttpClient) { super(helperService, http); }

  get FALL_BACK_SETTINGS(): IOltAppSettingResult<IAppSettings> {
    return FALL_BACK_CONFIG;
  }

  get settingsURL(): string {
    return '~/assets/app-settings.json';
  }

  
}
