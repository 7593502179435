<div class="modal-header bg-primary text-white">
  <h4 class="modal-title pull-left text-uppercase m-0 p-0">Service Entry</h4>
</div>

<form [formGroup]="entryForm" (ngSubmit)="save()" novalidate>
  <div class="modal-body">

    <div class="row">

      <div class="col-12">
        <olt-form-group>
          <span class="control-label">Start Date</span>
          <div class="input-group">
            <input class="form-control" [minDate]="settings.pickerConfig.minDate"
              [maxDate]="settings.pickerConfig.maxDate" placeholder="m/d/yyyy" #dStart="bsDatepicker"
              formControlName="start" bsDatepicker>
            <div class="input-group-append">
              <button type="button" class="btn btn-outline-secondary" (click)="dStart.toggle()"
                [attr.aria-expanded]="dStart.isOpen">
                <i class="fas fa-calendar"></i>
              </button>
            </div>
          </div>
        </olt-form-group>
      </div>


      <div class="col-12">
        <olt-form-group>
          <span class="control-label">End Date</span>
          <div class="input-group">
            <input class="form-control" [minDate]="endConfig.minDate" [maxDate]="endConfig.maxDate"
              placeholder="m/d/yyyy" #dEnd="bsDatepicker" formControlName="end" bsDatepicker>
            <div class="input-group-append">
              <button type="button" class="btn btn-outline-secondary" (click)="dEnd.toggle()"
                [attr.aria-expanded]="dEnd.isOpen">
                <i class="fas fa-calendar"></i>
              </button>
            </div>
          </div>
        </olt-form-group>
      </div>
    </div>


  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">Cancel</button>
  </div>

</form>
