import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';
import { ApiService } from './api.service';
import { OltUtility, ApiParams, OltAuthServiceBase, OltConfigServiceBase } from '@outerlimitstech/ngx-app-core';
import { Router } from '@angular/router';
import { AuthenticationToken } from 'bgcslib';
import { CONSTANTS } from '../models';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends OltAuthServiceBase {

  private readonly impersonatingTokenName: string = `${CONSTANTS.Storage.Token}_orig`;

  constructor(
    private apiService: ApiService,
    private configService: OltConfigServiceBase,
    private storageService: StorageService,
    private permissionsService: NgxPermissionsService,
    private router: Router
  ) { super(); }

  get identity(): AuthenticationToken | null {
    if (this.isAuthenticated) {
      return this.storageService.authenticatedUser;
    }
    return null;
  }

  get username(): string | null {
    if (this.isAuthenticated) {
      return this.storageService.authenticatedUser.username;
    }
    return null;
  }

  get tokenType(): string | null | undefined {
    if (this.isAuthenticated) {
      return this.storageService.authenticatedUser?.authenticationType;
    }
    return null;
  }

  get token(): string | null {
    if (this.isAuthenticated) {
      return this.storageService.authenticatedUser.token;
    }
    return null;
  }


  get isAuthenticated(): boolean {
    const val = this.storageService.authenticatedUser != null && this.storageService.authenticatedUser.isValid;
    this.loadPermissions(false);
    return val;
  }

  get fullName(): string | null {
    if (this.isAuthenticated) {
      return this.storageService.authenticatedUser.fullName;
    }
    return null;
  }


  hasPermission(permission: string): boolean {
    if (this.isAuthenticated) {
      const permissions = this.storageService.authenticatedUser?.permissions;
      if (permissions != null) {
        return permissions.find(p => p === permission) != null;
      }
    }
    return false;
  }


  loadPermissions(refresh: boolean): void {

    if (this.storageService.authenticatedUser == null) {
      return;
    }

    if (!OltUtility.isObjectEmpty(this.permissionsService.getPermissions()) && !refresh) {
      return;
    }

    const permissions = this.storageService.authenticatedUser.permissions;
    if (permissions != null && permissions.length > 0) {
      permissions.forEach(permission => {
        this.permissionsService.addPermission(permission);
      });
    }

    const roles = this.storageService.authenticatedUser.roles;
    if (roles != null && roles.length > 0) {
      roles.forEach(role => {
        this.permissionsService.addPermission(role);
      });
    }
  }

  logout() {
    this.storageService.authenticatedUser = null;
    this.permissionsService.flushPermissions();
  }

  changePassword(jsonData: any): Observable<boolean> {
    const url = '/account/changepassword';
    return this.apiService.doPost<boolean>(url, jsonData);
  }

  verifyEmailToken(identifier: string, token: string): Observable<boolean> {
    const url = '/account/verify';
    const postData = {
      identifier,
      token
    };
    return this.apiService.doPost<boolean>(url, postData);
  }

  completeVerification(
    identifier: string,
    token: string,
    newPassword: string,
    confirmPassword: string): Observable<boolean> {

    const url = '/account/verify/complete';
    const postData = {
      identifier,
      token,
      password: {
        newPassword,
        confirmPassword
      }
    };
    return this.apiService.doPost<boolean>(url, postData);
  }

  saveToken(data: AuthenticationToken): void {
    this.storageService.authenticatedUser = data;
  }

  login(): void {
    this.router.navigate([this.configService.loginRoute]);
  }

  processOpenIdToken(): void {
    throw new Error('Method not implemented.');
  }

  loginUser(username: string, password: string): Observable<AuthenticationToken> {
    const jsonData = {
      username,
      password
    };
    return this.apiService.doPost<AuthenticationToken>('/account/local/token/issue', jsonData);
  }

  forgotPassword(username: string): Observable<boolean> {
    const url = '/account/forgotPassword';
    const params = new ApiParams();
    params.append('username', username);
    return this.apiService.doPost<boolean>(url, null, params);
  }

  verifyPasswordResetToken(identifier: string, token: string): Observable<boolean> {
    const url = '/account/forgotPassword/validate';
    const postData = {
      identifier,
      token
    };
    return this.apiService.doPost<boolean>(url, postData);
  }

  completePasswordReset(
    identifier: string,
    token: string,
    newPassword: string,
    confirmPassword: string): Observable<boolean> {

    const url = '/account/forgotPassword';
    const postData = {
      identifier,
      token,
      password: {
        newPassword,
        confirmPassword
      }
    };
    return this.apiService.doPut<boolean>(url, postData);
  }

  get isImpersonating(): boolean {
    return this.storageService.impersonatingOriginalToken != null;
  }

  impersonate(userId: number): Observable<boolean> {
    return new Observable(observer => {
      return this.apiService
        .doPost<AuthenticationToken>(`users/${userId}/impersonate`, null)
        .subscribe(json => {
          if (this.storageService.impersonatingOriginalToken == null) {
            this.storageService.impersonatingOriginalToken = this.storageService.authenticatedUser;
          }
          this.storageService.authenticatedUser = json;
          this.permissionsService.flushPermissions();
          this.loadPermissions(true);
          observer.next(true);
          observer.complete();
        });
    });
  }

  stopImpersonating(): boolean {
    if (this.isImpersonating) {
      const prevToken = this.storageService.impersonatingOriginalToken;
      this.storageService.impersonatingOriginalToken = null;
      this.storageService.authenticatedUser = prevToken;
    }
    return true;
  }
}
