<div class="modal-header bg-primary text-white">
  <h4 class="modal-title pull-left text-uppercase m-0 p-0">Test Score</h4>
</div>

<form [formGroup]="entryForm" (ngSubmit)="save()" novalidate>
  <div class="modal-body">

    <div class="row">
      <div class="col-12">
        <olt-form-group>
          <span class="control-label">Score</span>
          <input type="text" class="form-control" autocomplete="off" oltIntEntry maxlength="3" formControlName="score">
        </olt-form-group>
      </div>

      <div class="col-12">
        <olt-form-group>
          <span class="control-label">Test Date</span>
          <div class="input-group">
            <input class="form-control" 
              [minDate]="settings.pickerConfig.minDate" 
              [maxDate]="settings.pickerConfig.maxDate"
              placeholder="m/d/yyyy" 
              #d="bsDatepicker" 
              formControlName="completed" 
              bsDatepicker>
            <div class="input-group-append">
              <button type="button" 
                class="btn btn-outline-secondary" 
                (click)="d.toggle()"
                [attr.aria-expanded]="d.isOpen">
                <i class="fas fa-calendar"></i>
              </button>
            </div>
          </div>
        </olt-form-group>
      </div>
    </div>


  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary">Save</button>
    <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">Cancel</button>
  </div>

</form>
