import { AuthenticatedUser } from '@outerlimitstech/ngx-app-core';

export class AuthenticationToken extends AuthenticatedUser {
    years = new Array<number>();
    clubs = new Array<number>();

    get currentYear(): number {
        return this.years[0];
    }

    constructor(data: any) {
        super(data);
        this.clubs = data?.clubs;
        this.years = data?.years;
    }

}
