export abstract class BaseStorageService { 
  
  protected readonly featureFlagName = "app.feature-flags";

  
  protected saveLocalStorage(name: string, value: any) {
    if (value != null) {
      localStorage.setItem(name, value);
    } else {
      localStorage.removeItem(name);
    }
  }

  protected saveSessionStorage(name: string, value: any) {
    if (value) {
      sessionStorage.setItem(name, value);
    } else {
      sessionStorage.removeItem(name);
    }
  }

  get featureFlags(): any | null {
    const value = sessionStorage.getItem(this.featureFlagName);
    if (value != null) {
      return JSON.parse(value);
    }
    return null;
  }
  set featureFlags(value: any[] | null) {
    this.saveSessionStorage(this.featureFlagName, value != null ? JSON.stringify(value) : null);
  }



}