import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

export class ClientStruggleWith {
    reading: boolean;
    math: boolean;

    public static formGroup(fb: UntypedFormBuilder, validators?: Validators[]): UntypedFormGroup {
        return fb.group({
            reading: [null, validators],
            math: [null, validators],
        });
    }

    constructor(data?: any) {
        if (data == null) {
            return;
        }

        this.reading = data.reading;
        this.math = data.math;
    }
}
