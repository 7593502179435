import { Client } from 'bgcslib';
import { ClientService } from './client.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { OltUtility } from '@outerlimitstech/ngx-app-core';


@Injectable({
  providedIn: 'root'
})
export class ClientNameResolve  {

  constructor(private service: ClientService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const id = OltUtility.toInt(route.paramMap.get('clientId'));
    return this.service.getName(id);
  }
}


@Injectable({
  providedIn: 'root'
})
export class ClientResolve  {

  constructor(private service: ClientService) { }

  resolve(route: ActivatedRouteSnapshot) {
    const id = OltUtility.toInt(route.paramMap.get('clientId'));
    return this.service.get(id);
  }
}
