
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpEvent } from '@angular/common/http';
import { ApiParams, OltApiService } from '@outerlimitstech/ngx-app-core';


@Injectable({
  providedIn: 'root'
})
export class ApiService extends OltApiService {
  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  uploadFile(url, file: File, params?: ApiParams): Observable<HttpEvent<{}>> {
    return super.uploadFile(url, file, params, null);
  }

  uploadFiles(url: string, files: File[], params?: ApiParams): Observable<HttpEvent<{}>> {
    return super.uploadFiles(url, files, params, null);
  }
}
