import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { CodeValue } from './code-value';
import { CodeValueOther } from './code-value-other';


export class ClientProgram {
  enrollDate?: string;
  exitType = new CodeValueOther();
  applicantType = new CodeValue();


  public static formGroup(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      enrollDate: [null, [Validators.required]],
      // applicantType: CodeValue.formGroup(fb),
      // exitType: CodeValueOther.formGroupOther(fb),
    });
  }

  public static formGroupSimple(fb: UntypedFormBuilder): UntypedFormGroup {
    return fb.group({
      enrollDate: [new Date()],
      // applicantType: CodeValue.formGroup(fb),
      // exitType: CodeValueOther.formGroupOther(fb),
    });
  }

  constructor(data?: any) {
    if (data == null) {
      return;
    }

    this.enrollDate = data.enrollDate;
    this.exitType = new CodeValueOther(data.exitType);
    this.applicantType = new CodeValue(data.applicantType);
  }

}
