import { ClientTest } from 'bgcslib';

export class EditTestScoreResult extends ClientTest {
    result = false;

    constructor(data?: any) {
        super(data);
        if (data == null) {
            return;
        }
        this.result = data.result;
    }
}
