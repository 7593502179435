<div class="modal-header bg-secondary text-white">
  <h4 class="modal-title pull-left text-uppercase m-0 p-0">Address Validation Results</h4>
</div>
<div class="modal-body">
  <h1>Here</h1>
  <!-- <table class="table table-striped table-hover">
    <thead>
      <tr>
        <th>Address</th>
        <th>County</th>
        <th class="text-right">
        </th>
      </tr>
    </thead>
    <tbody *ngIf="addressResult && addressResult.allPossibleLocations">
      <tr *ngFor="let record of addressResult.allPossibleLocations">
        <td>
          <span *ngIf="!record.fullAddress" [innerHtml]="record | address">
          </span>
          <span *ngIf="record.fullAddress">
            {{ record.fullAddress }}
          </span>
        </td>
        <td>
          {{record.county}}
        </td>
        <td class="text-right">
          <button type="button" class="btn btn-primary" (click)="select(record)">
            <i class="fas fa-map-marker-alt"></i> Select
          </button>
        </td>
      </tr>
    </tbody>
  </table> -->
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">Cancel</button>
</div>
