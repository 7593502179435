/*
 * Public API Surface of bgcslib
 */

export * from './lib/components/index';
export * from './lib/models/index';
export * from './lib/interfaces/index';
export * from './lib/services/index';
export { BgcsBaseViewComponent } from './lib/bgcs-base-view.components';
export * from './lib/bgcslib.module';
export { BaseConfigService } from './lib/services/base-config.service';
