import { NgModule } from '@angular/core';
import { BgcsBaseViewComponent } from './bgcs-base-view.components';
import { EXPORT_COMPONENTS } from './components/export';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [
    BgcsBaseViewComponent,
    EXPORT_COMPONENTS
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    BgcsBaseViewComponent,
    EXPORT_COMPONENTS
  ]
})
export class BgcslibModule { }
