import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Client } from 'bgcslib';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    protected apiService: ApiService
  ) { }

  get(id: number): Observable<any> {
    return this.apiService.doGet(`/clients/${id}`);
  }

  getByUid(uid: string): Observable<Client> {
    return this.apiService.doGet(`/clients/${uid}`);
  }

  getName(id: number): Observable<string> {
    return this.apiService.doGet(`clients/${id}/name`);
  }

  add(data: Client): Observable<Client> {
    return this.apiService.doPost(`/clients`, data);
  }

  update(id: number, data: Client): Observable<Client> {
    return this.apiService.doPut(`/clients/${id}`, data);
  }

  delete(id: number): Observable<boolean> {
    return this.apiService.doDelete(`/clients/${id}`);
  }

  renewEnrollment(id: number, data: Client): Observable<Client> {
    return this.apiService.doPut(`/clients/${id}/renewal`, data);
  }

}
