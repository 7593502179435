import { Address } from 'bgcslib';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AddressValidationResult, BaseViewComponent, AddressValidationService, AddressValidation } from '@app/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-address-suggestion-modal',
  templateUrl: './address-suggestion-modal.component.html',
  styleUrls: ['./address-suggestion-modal.component.scss']
})
export class AddressSuggestionModalComponent extends BaseViewComponent implements OnInit {
  result = new AddressValidationResult();
  address: Address;
  addressValidation = new AddressValidation();

  constructor(
    private addressValidationService: AddressValidationService,
    // private helperService: OltHelperService,
    private cd: ChangeDetectorRef,
    public bsModalRef: BsModalRef) { super(); }

  ngOnInit() {

    this.addressValidationService.validateAddress(this.address).subscribe(validationResult => {

    });


  }

}
