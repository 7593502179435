<div class="row d-print-none">
  <div class="col-12 col-xl-6">
    <ng-container *ngTemplateOutlet="leftCol"></ng-container>
  </div>
  <div class="col-12 col-xl-6">
    <ng-container *ngTemplateOutlet="rightCol"></ng-container>
  </div>
</div>

<div class="d-none d-print-block">
  <div class="row">
    <div class="col-6">
      <ng-container *ngTemplateOutlet="leftCol"></ng-container>
    </div>
    <div class="col-6">
      <ng-container *ngTemplateOutlet="rightCol"></ng-container>
    </div>
  </div>
</div>

<ng-template #leftCol>
  <ul class="view-info">
    <li>
      <span class="view-label">Score:</span>
      <div class="view-data">
        {{ clientTest.score }}
      </div>
    </li>
    <li>
      <span class="view-label">Grade Equivalency:</span>
      <div class="view-data">
        {{ clientTest.gradeEquivalency?.value | number:'1.2' }}
      </div>
      <div class="view-data small font-italic"
        [class.text-danger]="clientTest.gradeEquivalency?.value < clientTest.gradeEquivalency?.gradeLevel">
        {{ clientTest.gradeEquivalency?.summary }}
      </div>
    </li>
    <li>
      <span class="view-label">Test Date:</span>
      <div class="view-data">
        {{ clientTest.completed | date:'M/d/yyyy' }}
      </div>
    </li>
  </ul>
</ng-template>


<ng-template #rightCol>
  <ul class="view-info">
    <li>
      <span class="view-label">Improvement:</span>
      <div class="view-data">
        {{ clientTest.gradeEquivalency?.percentageImprovement | percent:'1.2-2' | empty }}
      </div>
    </li>
    <li>
      <span class="view-label">Number Months in Program:</span>
      <div class="view-data">
        {{ clientTest.gradeEquivalency?.numberMonths | number:'1.0' | empty }}
      </div>
    </li>
    <li>
      <span class="view-label">Number of Months of Improvement:</span>
      <div class="view-data">
        {{ clientTest.gradeEquivalency?.numberMonthsImprove | number:'1.2' | empty }}
      </div>
    </li>
  </ul>
</ng-template>
