import { Params } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PagedParam, Paged, ApiParams } from '@outerlimitstech/ngx-app-core';
import { Observable } from 'rxjs';
import { BillingSummary } from '../models/billing-summary';
import { QuickSearchClient } from '../models/quick-search-client';

@Injectable({
  providedIn: 'root'
})
export class ContractService {

  constructor(
    protected apiService: ApiService
  ) { }

  getBillingSummary(pagingParameter: PagedParam): Observable<Paged<BillingSummary>> {
    return this.apiService.doGet(`/contracts/billing-summary`, pagingParameter.toApiParams());
  }


  getMatrix(year: number): Observable<any> {
    return this.apiService.doGet(`/contracts/years/${year}/services/matrix`);
  }

  getMatrixDetail(year: number, filter: QuickSearchClient, pagingParameter: PagedParam): Observable<Paged<any>> {
    const params = new ApiParams();
    params.appendParams(filter.getAll());
    params.appendParams(pagingParameter.getAll());
    return this.apiService.doGet(`/contracts/years/${year}/services/matrix/detail`, params);
  }
}
