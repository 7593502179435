import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PagedParam, Paged, ApiParams } from '@outerlimitstech/ngx-app-core';
import { Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class ClubService {

  constructor(private apiService: ApiService) { }

  getAll(pagedParams: PagedParam, searchText?: string): Observable<Paged<any>> {
    const params = new ApiParams();
    params.appendParams(pagedParams.getAll());
    if (searchText != null) {
      params.append('searchText', searchText);
    }
    return this.apiService.doGet(`clubs`, params);
  }

  get(clubId: number): Observable<any> {
    return this.apiService.doGet(`clubs/${clubId}`);
  }

  update(clubId: number, data: any): Observable<any> {
    return this.apiService.doPut<any>(`clubs/${clubId}`, data);
  }

  add(data: any): Observable<any> {
    return this.apiService.doPost<any>(`clubs`, data);
  }

  delete(clubId: number): Observable<any> {
    return this.apiService.doDelete<any>(`clubs/${clubId}`);
  }

}
