
export class GradeEquivalency {
  score: number;
  value: number;
  gradeLevel: number;
  percentageImprovement: number | null;
  summary: string;
  numberMonths: number | null;
  numberMonthsImprove: number | null;

  constructor(data?: any) {
    if (data == null) {
      return;
    }
    this.score = data.score;
    this.value = data.value;
    this.gradeLevel = data.gradeLevel;
    this.summary = data.summary;
    this.percentageImprovement = data.percentageImprovement;
    this.numberMonths = data.numberMonths;
    this.numberMonthsImprove = data.numberMonthsImprove
      ;
  }

}
