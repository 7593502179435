import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

export class ClientDiagnosed {
    adhd?: boolean;
    learningDisability?: boolean;
    otherDisability?: boolean;

    public static formGroup(fb: UntypedFormBuilder): UntypedFormGroup {
        return fb.group({
            adhd: [null, [Validators.required]],
            learningDisability: [null, [Validators.required]],
            otherDisability: [false],
            otherDisabilityText: [null],
        });
    }

    public static formGroupSimple(fb: UntypedFormBuilder): UntypedFormGroup {
        return fb.group({
            adhd: [null],
            learningDisability: [null],
            otherDisability: [false],
            otherDisabilityText: [null],
        });
    }

    constructor(data?: any) {
        if (data == null) {
            return;
        }
        this.adhd = data.adhd;
        this.learningDisability = data.learningDisability;
        this.otherDisability = data.otherDisability;
    }
}
