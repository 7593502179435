import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Params } from '@angular/router';
import { OltUtility, ApiParams, ApiParameter, IApiParam } from '@outerlimitstech/ngx-app-core';
import { CodeValue } from 'bgcslib';

export class QuickSearchClient implements IApiParam {
  searchText!: string;
  year!: number | null;
  showArchived!: boolean;
  club = new CodeValue();

  public static formGroup(fb: UntypedFormBuilder, data?: any, required: boolean = false): UntypedFormGroup {
    const validators = required ? [Validators.required] : [];
    return fb.group({
      searchText: [data?.searchText, validators],
      year: [data?.year],
      showArchived: [data?.showArchived],
      club: fb.group({
        id: [null],
      }),
    });
  }

  get queryString(): any {
    const value: any = {};
    if (this.year != null && this.year > 0) {
      value.year = this.year;
    }
    if (this.searchText != null) {
      value.q = this.searchText;
    }
    if (this.showArchived != null) {
      value.archived = this.showArchived;
    }
    if (this.club.id > 0) {
      value.club = this.club.id;
    }
    return value;
  }

  fromFormGroup(entryForm: UntypedFormGroup): void {
    this.year = OltUtility.toInt(entryForm.get('year')?.value, null);
    this.searchText = entryForm.get('searchText')?.value;
    this.showArchived = OltUtility.toBoolean(entryForm.get('showArchived')?.value, null);
    this.club.id = OltUtility.toInt(entryForm.get('club.id')?.value, null);
  }

  fromQueryParams(params: Params, defaults?: any): void {
    this.year = OltUtility.toInt(params.get('year'), defaults?.year);
    this.showArchived = OltUtility.toBoolean(params.get('archived'), defaults?.showArchived);
    this.club.id = OltUtility.toInt(params.get('club'), null);
    this.searchText = params.get('q') ? params.get('q') : defaults?.searchText;
  }

  getAll(): ApiParameter[] {
    const params = new Array<ApiParameter>();
    params.push(new ApiParameter('year', this.year));
    params.push(new ApiParameter('searchText', this.searchText));
    params.push(new ApiParameter('showArchived', this.showArchived));
    params.push(new ApiParameter('clubId', this.club.id));
    return params;
  }

  toApiParams(): ApiParams {
    const params = new ApiParams();
    params.appendParams(this.getAll());
    return params;
  }


  constructor(data?: any) {
    this.searchText = data?.searchText;
    this.year = data?.year;
    this.showArchived = data?.showArchived;
    this.club.id = data?.clubId;
  }

}
