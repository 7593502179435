import { Service } from 'bgcslib';

export class BillingCount {
  clients: number;
  services: number;

  constructor(data?: any) {
    if (data == null) {
      return;
    }
    this.clients = data.clients;
    this.services = data.services;
  }
}

export class BillingDetail {
  clientContractServiceId?: number;
  service = new Service();
  start: Date;
  end: Date;
}
